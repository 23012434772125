<template>
  <div>
    <Modal size="sm" ref="modal__chart">
      <template #title>
        {{ selectedFolder ? 'Editar folder' : 'Nuevo folder' }}
      </template>
      <template #content>
        <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
          <form @submit.prevent="handleSubmit(onAction)">
            <div class="chart__modal">
              <span>
                <custom-input v-model="folder.name" label="Nombre" rules="required" />
              </span>
            </div>
            <div class="modal__buttons">
              <Button
                :disabled="isLoading"
                type="button"
                variant="text"
                size="small"
                @click="close"
              >
                Cancelar
              </Button>
              <Button
                v-if="!selectedFolder"
                type="submit"
                :disabled="invalid || isLoading"
                variant="primary"
                size="small"
              >
                {{ !isLoading ? 'Crear folder' : 'Agregando...' }}
              </Button>
              <Button
                v-else
                type="submit"
                :disabled="invalid || isLoading"
                variant="primary"
                size="small"
              >
                {{ !isLoading ? 'Editar folder' : 'Editando...' }}
              </Button>
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Button from '@/components/buttons/Button.vue';
import CustomInput from '@/components/custom/CustomInput.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'FolderModal',
  components: {
    Button,
    Modal,
    CustomInput,
    ValidationObserver,
  },
  data() {
    return {
      folder: {},
      isLoading: false,
      selectedFolder: null,
    };
  },

  props: {
    selectedFolderId: {
      type: String,
      default: '',
    },
  },

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('files', ['addFolder', 'updateFolder']),

    async createFolder() {
      this.folder.parentId = this.selectedFolderId;
      this.folder.type = 'folder';
      this.folder.contentType = 'folder';
      this.folder.timeCreated = new Date();
      await this.addFolder({ metadata: this.folder });
      this.setAlert({
        state: 'success',
        message: 'Folder creado',
      });
    },

    async editFolder() {
      await this.updateFolder({ id: this.selectedFolder.id, metadata: this.folder });
      this.setAlert({
        state: 'success',
        message: 'Folder editado',
      });
    },

    async onAction() {
      this.isLoading = true;
      try {
        if (!this.selectedFolder) await this.createFolder();
        else await this.editFolder();
      } catch (error) {
        console.log(error);
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      }
      this.isLoading = false;
      this.close();
      this.$emit('update');
    },

    async open() {
      if (this.selectedFolder) {
        this.folder = JSON.parse(JSON.stringify(this.selectedFolder.metadata));
        this.folder.timeCreated = this.selectedFolder.metadata.timeCreated;
      }
      this.$refs.modal__chart.open();
    },

    close() {
      this.folder = {};
      this.selectedFolder = null;
      this.$refs.modal__chart.close();
    },
  },

  computed: {
    ...mapState({}),
  },
};
</script>

<style lang="scss" scoped>
.form__validation {
  height: 100%;

  form {
    height: 100%;
  }
}

.chart__modal {
  padding: 1.8rem 1.8rem;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;

  & > span:first-child {
    display: flex;
    flex-flow: column;
    gap: 0.8rem;
  }
}

.chart__buttons--radio {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.radio__label {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 5.5rem;
  height: 4.5rem;
  color: var(--font-color-200);
}

.radio__label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  border: solid 2px var(--gray-color-200);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  p {
    font-size: 0.7rem;
    line-height: 0.7rem;
    font-weight: var(--light);
  }
}

/* On mouse-over, add a grey background color */
.radio__label:hover input:not(:checked) ~ .checkmark {
  background-color: var(--gray-color-100);
}

/* When the radio button is checked, add a blue background */
.radio__label input:checked ~ .checkmark {
  border-color: var(--main-color-500);
  * {
    color: var(--main-color-500);
    font-weight: vaR(--regular);
  }
}
</style>
