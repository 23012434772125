export default {
  fileTypes: [
    {
      Extension: '.aac',
      'Kind of document': 'AAC audio',
      'MIME Type': 'audio/aac',
      svg: 'aac.svg',
    },
    {
      Extension: '.avi',
      'Kind of document': 'AVI: Audio Video Interleave',
      'MIME Type': 'video/x-msvideo',
      svg: 'avi.svg',
    },
    {
      Extension: '.bin',
      'Kind of document': 'Any kind of binary data',
      'MIME Type': 'application/octet-stream',
      svg: 'bin.svg',
    },
    {
      Extension: '.bmp',
      'Kind of document': 'Windows OS/2 Bitmap Graphics',
      'MIME Type': 'image/bmp',
      svg: 'bmp.svg',
    },
    {
      Extension: '.css',
      'Kind of document': 'Cascading Style Sheets (CSS)',
      'MIME Type': 'text/css',
      svg: 'css.svg',
    },
    {
      Extension: '.csv',
      'Kind of document': 'Comma-separated values (CSV)',
      'MIME Type': 'text/csv',
      svg: 'csv.svg',
    },
    {
      Extension: '.doc',
      'Kind of document': 'Microsoft Word',
      'MIME Type': 'application/msword',
      svg: 'doc.svg',
    },
    {
      Extension: '.docx',
      'Kind of document': 'Microsoft Word (OpenXML)',
      'MIME Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      svg: 'docx.svg',
    },
    {
      Extension: '.gz',
      'Kind of document': 'GZip Compressed Archive',
      'MIME Type': 'application/gzip',
      svg: 'gz.svg',
    },
    {
      Extension: '.gif',
      'Kind of document': 'Graphics Interchange Format (GIF)',
      'MIME Type': 'image/gif',
      svg: 'gif.svg',
    },
    {
      Extension: '.html',
      'Kind of document': 'HyperText Markup Language (HTML)',
      'MIME Type': 'text/html',
      svg: 'html.svg',
    },
    {
      Extension: '.ico',
      'Kind of document': 'Icon format',
      'MIME Type': 'image/vnd.microsoft.icon',
      svg: 'ico.svg',
    },
    {
      Extension: '.ics',
      'Kind of document': 'iCalendar format',
      'MIME Type': 'text/calendar',
      svg: 'ics.svg',
    },
    {
      Extension: '.jar',
      'Kind of document': 'Java Archive (JAR)',
      'MIME Type': 'application/java-archive',
      svg: 'jar.svg',
    },
    {
      Extension: '.jpg',
      'Kind of document': 'JPEG images',
      'MIME Type': 'image/jpeg',
      svg: 'jpg.svg',
    },
    {
      Extension: '.js',
      'Kind of document': 'JavaScript',
      'MIME Type': '',
      svg: 'js.svg',
    },
    {
      Extension: '.mid',
      'Kind of document': '',
      'MIME Type': '',
      svg: 'mid.svg',
    },
    {
      Extension: '.mp3',
      'Kind of document': 'MP3 audio',
      'MIME Type': 'audio/mpeg',
      svg: 'mp3.svg',
    },
    {
      Extension: '.mp4',
      'Kind of document': 'MP4 audio',
      'MIME Type': 'video/mp4',
      svg: 'mp4.svg',
    },
    {
      Extension: '.odp',
      'Kind of document': 'OpenDocument presentation document',
      'MIME Type': 'application/vnd.oasis.opendocument.presentation',
      svg: 'odp.svg',
    },
    {
      Extension: '.ods',
      'Kind of document': 'OpenDocument spreadsheet document',
      'MIME Type': 'application/vnd.oasis.opendocument.spreadsheet',
      svg: 'ods.svg',
    },
    {
      Extension: '.odt',
      'Kind of document': 'OpenDocument text document',
      'MIME Type': 'application/vnd.oasis.opendocument.text',
      svg: 'odt.svg',
    },
    {
      Extension: '.otf',
      'Kind of document': 'OpenType font',
      'MIME Type': 'font/otf',
      svg: 'otf.svg',
    },
    {
      Extension: '.png',
      'Kind of document': 'Portable Network Graphics',
      'MIME Type': 'image/png',
      svg: 'png.svg',
    },
    {
      Extension: '.pdf',
      'Kind of document': 'Adobe Portable Document Format (PDF)',
      'MIME Type': 'application/pdf',
      svg: 'pdf.svg',
    },
    {
      Extension: '.ppt',
      'Kind of document': 'Microsoft PowerPoint',
      'MIME Type': 'application/vnd.ms-powerpoint',
      svg: 'ppt.svg',
    },
    {
      Extension: '.pptx',
      'Kind of document': 'Microsoft PowerPoint (OpenXML)',
      'MIME Type': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      svg: 'pptx.svg',
    },
    {
      Extension: '.rar',
      'Kind of document': 'RAR archive',
      'MIME Type': 'application/vnd.rar',
      svg: 'rar.svg',
    },
    {
      Extension: '.rtf',
      'Kind of document': 'Rich Text Format (RTF)',
      'MIME Type': 'application/rtf',
      svg: 'rtf.svg',
    },
    {
      Extension: '.svg',
      'Kind of document': 'Scalable Vector Graphics (SVG)',
      'MIME Type': 'image/svg+xml',
      svg: 'svg.svg',
    },
    {
      Extension: '.swf',
      'Kind of document': 'Small web format (SWF) or Adobe Flash document',
      'MIME Type': 'application/x-shockwave-flash',
      svg: 'swf.svg',
    },
    {
      Extension: '.tar',
      'Kind of document': 'Tape Archive (TAR)',
      'MIME Type': 'application/x-tar',
      svg: 'tar.svg',
    },
    {
      Extension: '.tiff',
      'Kind of document': 'Tagged Image File Format (TIFF)',
      'MIME Type': 'image/tiff',
      svg: 'tiff.svg',
    },
    {
      Extension: '.ttf',
      'Kind of document': 'TrueType Font',
      'MIME Type': 'font/ttf',
      svg: 'ttf.svg',
    },
    {
      Extension: '.txt',
      'Kind of document': 'Text, (generally ASCII or ISO 8859-n)',
      'MIME Type': 'text/plain',
      svg: 'txt.svg',
    },
    {
      Extension: '.wav',
      'Kind of document': 'Waveform Audio Format',
      'MIME Type': 'audio/wav',
      svg: 'wav.svg',
    },
    {
      Extension: '.xhtml',
      'Kind of document': 'XHTML',
      'MIME Type': 'application/xhtml+xml',
      svg: 'xhtml.svg',
    },
    {
      Extension: '.xls',
      'Kind of document': 'Microsoft Excel',
      'MIME Type': 'application/vnd.ms-excel',
      svg: 'xls.svg',
    },
    {
      Extension: '.xlsx',
      'Kind of document': 'Microsoft Excel (OpenXML)',
      'MIME Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      svg: 'xlsx.svg',
    },
    {
      Extension: '.xml',
      'Kind of document': 'XML',
      'MIME Type': 'application/xml if not readable from casual users (RFC 3023, section 3)',
      svg: 'xml.svg',
    },
    {
      Extension: '.zip',
      'Kind of document': 'ZIP archive',
      'MIME Type': 'application/zip',
      svg: 'zip.svg',
    },
    {
      Extension: '.3gp',
      'Kind of document': '3GPP audio/video container',
      'MIME Type': 'video/3gpp',
      svg: '3gp.svg',
    },
    {
      Extension: '.3g2',
      'Kind of document': '3GPP2 audio/video container',
      'MIME Type': 'video/3gpp2',
      svg: '3g2.svg',
    },
    {
      Extension: '.7z',
      'Kind of document': '7-zip archive',
      'MIME Type': 'application/x-7z-compressed',
      svg: '7zip.svg',
    },
    {
      Extension: '.folder',
      svg: 'folder.svg',
    },
  ],
};
