<template>
  <div class="steps-container">
    <template v-for="(step, index) in steps">
      <div
        class="step"
        :key="`step-${index}`"
        :class="{ 'step--selected': index + 1 === currentStep }"
      >
        <unicon
          v-if="index + 1 < currentStep"
          name="check-circle"
          height="16.8"
          width="16.8"
          class="step__check-icon"
        ></unicon>
        <unicon class="step__icon" v-else :name="step.icon" height="16.8" width="16.8"></unicon>
        <span>{{ index + 1 }}.&nbsp;{{ step.name }}</span>
      </div>
      <unicon
        :key="`icon-${index}`"
        v-if="index + 1 !== steps.length"
        class="icon"
        fill="currentColor"
        height="20px"
        name="angle-right"
        width="20px"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: Number,
    steps: Array,
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.steps-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.step {
  border: solid 1px var(--gray-color-400);
  border-radius: 15px;
  padding: 0.3rem 0.7rem 0.3rem 0.4rem;
  color: var(--font-color-200);
  display: flex;
  gap: 0.4rem;
  align-items: center;
  font-weight: var(--medium);
  span {
    font-size: 0.825rem;
  }
  &__icon {
    fill: var(--font-color-200);
  }
  &__check-icon {
    fill: var(--main-color-400);
  }
  &--selected {
    border: solid 1px var(--main-color-300);
    background-color: var(--main-color-000);
    color: var(--main-color-400);
    .unicon {
      fill: var(--main-color-400);
    }
  }
}

.icon {
  color: var(--font-color-100);
}
</style>
