var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Modal',{ref:"modal__chart",attrs:{"size":"slg"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Email archivo ")]},proxy:true},{key:"content",fn:function(){return [_c('link',{attrs:{"rel":"preload","href":_vm.signatureURL,"as":"image"}}),_c('div',{staticClass:"steps__container"},[_c('div',{staticClass:"steps__header"},[_c('StepsHeader',{attrs:{"steps":_vm.steps,"currentStep":_vm.currentStep}})],1),(_vm.currentStep === 1)?_c('div',{staticClass:"form__validation"},[_c('form',[_c('div',{staticClass:"email__modal"},[_c('span',{staticClass:"email__content"},[_c('div',{staticClass:"subject__input"},[_c('p',[_vm._v("Asunto:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.msg.subject),expression:"msg.subject"}],attrs:{"type":"text"},domProps:{"value":(_vm.msg.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.msg, "subject", $event.target.value)}}})]),_c('div',[_c('tiptap-editor',{on:{"save":_vm.onSave},model:{value:(_vm.msg.html),callback:function ($$v) {_vm.$set(_vm.msg, "html", $$v)},expression:"msg.html"}})],1)])]),_c('div',{staticClass:"modal__buttons"},[_c('Button',{attrs:{"disabled":_vm.isLoading,"type":"button","variant":"text","size":"small"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('Button',{attrs:{"type":"submit","disabled":_vm.isLoading,"variant":"primary","size":"small"},on:{"click":function($event){_vm.currentStep += 1}}},[_vm._v(" Siguiente ")])],1)])]):_vm._e(),(_vm.currentStep === 2)?_c('div',{staticClass:"form__validation"},[_c('div',{staticClass:"email__modal"},[_c('span',{staticClass:"email__content"},[_c('Filters',{attrs:{"filters":_vm.defaultCategories.concat( _vm.categories),"filtersOptions":_vm.defaultCategoriesOptions.concat( _vm.options)},on:{"filter":function (activeFilters) {
                    _vm.filterEmployees(activeFilters);
                  }}}),_c('div',{staticClass:"employees__container"},[_c('table',{ref:"employeesTable"},[_c('tbody',[_c('tr',[_c('th',[_c('checkbox',{model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1),_c('th',[_vm._v("#")]),_c('th',[_vm._v("Estado")]),_c('th',[_vm._v("Número de documento")]),_c('th',[_vm._v("Nombre")]),_c('th',[_vm._v("Email")])]),(_vm.filteredEmployees.length && !_vm.isLoading)?_vm._l((_vm.filteredEmployees.slice(
                          (_vm.currentPage - 1) * 100,
                          _vm.currentPage * 100
                        )),function(employee,index){return _c('tr',{key:employee.id,class:{
                          selected: _vm.selectedEmployees.includes(employee.id),
                        }},[_c('td',[_c('checkbox',{attrs:{"value":employee.id},model:{value:(_vm.selectedEmployees),callback:function ($$v) {_vm.selectedEmployees=$$v},expression:"selectedEmployees"}})],1),_c('td',[_vm._v(_vm._s(index + 1 + (_vm.currentPage - 1) * 100))]),_vm._l((_vm.defaultCategories.filter(function (ref) {
                                              var id = ref.id;

                                              return ['state', 'documentNumber', 'name', 'email'].includes(id);
                        }
                          )),function(defaultCategory){return [(defaultCategory.id === 'state')?_c('td',{key:defaultCategory.id},[_c('span',{staticClass:"state__cell",class:employee.state === 'Cesado'
                                  ? 'state__cell--terminated'
                                  : 'state__cell--active'},[_vm._v(_vm._s(employee.state))])]):_c('td',{key:defaultCategory.id},[_vm._v(" "+_vm._s(employee[defaultCategory.id])+" ")])]})],2)}):_vm._e()],2)])])],1)]),_c('div',{staticClass:"modal__buttons"},[_c('Button',{attrs:{"disabled":_vm.isLoading,"type":"button","variant":"text","size":"small"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('div',[_c('Button',{attrs:{"disabled":_vm.isLoading,"type":"button","variant":"secondary","size":"small"},on:{"click":function($event){_vm.currentStep -= 1}}},[_vm._v(" Anterior ")]),_c('Button',{attrs:{"disabled":_vm.selectedEmployees.length === 0 || _vm.isLoading,"variant":"primary","size":"small"},on:{"click":function($event){_vm.currentStep += 1}}},[_vm._v(" Siguiente ")])],1)],1)]):_vm._e(),(_vm.currentStep === 3)?_c('div',{staticClass:"form__validation"},[_c('div',{staticClass:"email__modal"},[_c('span',{staticClass:"email__content"},[_c('div',{staticClass:"subject__input"},[_c('p',[_vm._v("Asunto:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.msg.subject),expression:"msg.subject"}],attrs:{"type":"text","disabled":""},domProps:{"value":(_vm.msg.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.msg, "subject", $event.target.value)}}})]),_c('div',[_c('div',{staticClass:"html__content",domProps:{"innerHTML":_vm._s(_vm.msg.html)}})]),_c('div',{staticClass:"email__signature"},[_c('img',{attrs:{"src":_vm.signatureURL,"alt":""}})]),_c('div',{staticClass:"email__attachments"},_vm._l((_vm.files),function(file){return _c('div',{key:file.id,staticClass:"email__attachment"},[_c('figure',{staticClass:"fileCard__img"},[_c('img',{attrs:{"src":require(("@/assets/fileTypes/" + (_vm.fileTypes.find(
                            function (fileType) { return fileType.Extension === ("." + (file.contentType)); }
                          )
                            ? _vm.fileTypes.find(
                                function (fileType) { return fileType.Extension === ("." + (file.contentType)); }
                              ).svg
                            : 'file.svg'))),"alt":""}})]),_c('p',[_vm._v(_vm._s(file.name || '---')+"."+_vm._s(file.contentType))])])}),0)])]),_c('div',{staticClass:"modal__buttons"},[_c('Button',{attrs:{"disabled":_vm.isLoading,"type":"button","variant":"text","size":"small"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('div',[_c('Button',{attrs:{"disabled":_vm.isLoading,"type":"button","variant":"secondary","size":"small"},on:{"click":function($event){_vm.currentStep -= 1}}},[_vm._v(" Anterior ")]),_c('Button',{attrs:{"disabled":_vm.isLoading,"variant":"primary","size":"small"},on:{"click":function($event){_vm.currentStep += 1}}},[_vm._v(" Siguiente ")])],1)],1)]):_vm._e(),(_vm.currentStep === 4)?_c('div',{staticClass:"form__validation"},[_c('div',{staticClass:"email__modal"},[_c('span',{staticClass:"email__content"},[_c('div',{staticClass:"employees__container"},[_c('div',{staticClass:"confirmation__message"},[_c('p',[_vm._v("Los siguientes empleados no tienen un email y no seran procesados")])]),_c('table',{ref:"employeesTable"},[_c('tbody',[_c('tr',[_c('th'),_c('th',[_vm._v("#")]),_c('th',[_vm._v("Número de documento")]),_c('th',[_vm._v("Nombre")]),_c('th',[_vm._v("Email")])]),(_vm.selectedEmployeesEmails.filter(function (ref) {
                                          var email = ref.email;

                                          return !email;
}))?_vm._l((_vm.selectedEmployeesEmails.filter(
                          function (ref) {
                                            var email = ref.email;

                                            return !email;
}
                        )),function(employee,index){return _c('tr',{key:employee.id},[_c('td',[_c('div',{staticClass:"x"},[_c('unicon',{attrs:{"name":"exclamation-triangle","height":"16","width":"16"}})],1)]),_c('td',[_vm._v(_vm._s(index + 1 + (_vm.currentPage - 1) * 100))]),_vm._l((_vm.defaultCategories.filter(function (ref) {
                                              var id = ref.id;

                                              return ['documentNumber', 'name', 'email'].includes(id);
                        }
                          )),function(defaultCategory){return [_c('td',{key:defaultCategory.id},[_vm._v(" "+_vm._s(employee[defaultCategory.id] || '-')+" ")])]})],2)}):_vm._e()],2)])])])]),_c('div',{staticClass:"modal__buttons"},[_c('Button',{attrs:{"disabled":_vm.isLoading,"type":"button","variant":"text","size":"small"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('div',[_c('Button',{attrs:{"disabled":_vm.isLoading,"type":"button","variant":"secondary","size":"small"},on:{"click":function($event){_vm.currentStep -= 1}}},[_vm._v(" Anterior ")]),_c('Button',{attrs:{"disabled":_vm.isLoading,"variant":"primary","size":"small"},on:{"click":_vm.onAction}},[_vm._v(" Enviar ")])],1)],1)]):_vm._e()])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }