<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[]"
          :currentView="{ label: 'Gestión de documentos', icon: 'folder-open' }"
        />
        <h2>Documentos</h2>
      </div>
      <div class="content__buttons">
        <Button
          v-if="selectedFiles.length"
          type="button"
          variant="secondary"
          size="small"
          @click="openSendEmailModal(selectedFiles.filter((url) => isValidUrl(url)))"
        >
          <unicon
            width="16px"
            height="16px"
            name="envelope-upload"
            fill="var(--main-color-500)"
          ></unicon>
          Email
        </Button>
        <Menu direction="left">
          <template #label>
            <Button type="button" variant="secondary" size="xsmall">
              <unicon
                class="ellipsis"
                name="ellipsis-h"
                fill="currentColor"
                height="16px"
                width="16px"
              />
            </Button>
          </template>
          <template #options>
            <menu-item @click="showFilters = !showFilters">
              <unicon
                width="16px"
                height="16px"
                name="filter"
                fill="var(--font-color-700)"
              ></unicon>
              Filtrar
            </menu-item>
            <menu-item @click="showFilters = !showFilters">
              <unicon
                width="16px"
                height="16px"
                name="arrows-v"
                fill="var(--font-color-700)"
              ></unicon>
              Ordenar
            </menu-item>
          </template>
        </Menu>
        <Button type="button" variant="primary" size="small" @click="openNewFolderModal">
          <unicon width="16px" height="16px" name="plus" fill="white"></unicon>
          Crear carpeta
        </Button>
        <Button type="button" variant="primary" size="small">
          <label for="upload-file" class="file-input__label">
            <unicon width="16px" height="16px" name="upload-alt" fill="white"></unicon>
            Subir archivo
          </label>
        </Button>
        <input class="hidden" id="upload-file" type="file" @change="handleFileChange" />
      </div>
    </div>

    <div v-show="showFilters" class="filter__container">
      <div>
        <sort-item
          v-model="sortParam"
          :options="metadata"
          @change="
            filteredFiles = [
              ...folders,
              ...filteredFiles.filter(({ metadata }) => metadata.type !== 'folder').sort(compare),
            ]
          "
        />
        <span class="sort__divider"></span>
        <Filters @filter="filterFiles($event)" :filters="metadata" :filtersOptions="[]" />
      </div>
    </div>

    <div class="content">
      <div class="folders__buttons">
        <div class="breadcrumb__folders">
          <template
            v-for="view in folderFamily
              .map((folder) => ({
                name: folder.metadata.name,
                id: folder.id,
              }))
              .slice(0, folderFamily.length - 1)"
          >
            <span @click="changeFolder(view.id)" :key="`${view.name}-name`">
              <p>{{ view.name }}</p>
            </span>
            <span :key="`${view.name}-icon`">
              <unicon name="angle-right" height="20" width="20"></unicon>
            </span>
          </template>
          <p>
            {{ folderFamily[folderFamily.length - 1].metadata.name }}
          </p>
        </div>

        <ButtonGroup
          v-model="displayType"
          :buttonList="[
            { value: 'table', icon: 'table' },
            { value: 'thumbnails', icon: 'apps' },
          ]"
        />
      </div>
      <div v-if="displayType === 'thumbnails'">
        <div
          v-if="filteredFiles.length"
          class="files__container"
          :class="{ 'files__container-empty': !filteredFiles.length }"
        >
          <file-card
            v-for="(file, index) in filteredFiles"
            @click="file.metadata.type === 'folder' ? changeFolder(file.id) : ''"
            :key="index"
            :file="file"
            @edit="
              file.metadata.type === 'folder'
                ? openEditFolderModal($event)
                : openEditFileModal($event)
            "
            @delete="onDelete"
            @download="downloadFile"
            @email="(file) => openSendEmailModal([file.fileURL])"
          />
        </div>

        <empty-state
          class="empty-state"
          v-if="!isLoading && !filteredFiles.length"
          label="archivos"
          @click="openNewFolderModal"
        />
        <div v-if="isLoading" class="loading"><loading-spinner /></div>
      </div>
      <div v-else>
        <div>
          <table ref="filesTable">
            <tbody>
              <tr>
                <th></th>
                <th><checkbox v-model="selectAll" /></th>
                <th class="number">#</th>
                <th
                  :class="{ sortActive: sortParam.text === data.id }"
                  v-for="(data, index) in metadata"
                  :key="data.id"
                >
                  <Menu :direction="index === metadata.length - 1 ? 'left' : 'below'" type="table">
                    <template #label>
                      <div class="category__label">
                        <p>{{ data.name }}</p>
                        <unicon
                          :name="
                            sortParam.text === data.id
                              ? sortParam.order === 'up'
                                ? 'sort-amount-up'
                                : 'sort-amount-down'
                              : 'sort'
                          "
                          fill="currentColor"
                          height="13px"
                          width="13px"
                        />
                      </div>
                    </template>
                    <template #options>
                      <menu-item @click="changeSortParam(data.id, 'up')">
                        <unicon
                          width="16px"
                          height="16px"
                          name="sort-amount-up"
                          fill="var(--font-color-700)"
                        ></unicon>
                        Ordenar creciente
                      </menu-item>
                      <menu-item @click="changeSortParam(data.id, 'down')">
                        <unicon
                          width="16px"
                          height="16px"
                          name="sort-amount-down"
                          fill="var(--font-color-700)"
                        ></unicon>
                        Ordenar decreciente
                      </menu-item>
                    </template>
                  </Menu>
                </th>
              </tr>
              <template v-if="!isLoading && filteredFiles && filteredFiles.length">
                <tr
                  v-for="(file, index) in filteredFiles"
                  :key="`file-${index}`"
                  :class="{
                    selected: selectedFiles.includes(file.id),
                    isFolder: file.metadata.type === 'folder',
                  }"
                >
                  <td>
                    <Menu
                      :direction="
                        filteredFiles.length > 2 ?
                          [filteredFiles.length - 1, filteredFiles.length - 2].includes(index)
                            ? 'above'
                            : ''
                        : ''
                      "
                    >
                      <template #label>
                        <unicon
                          class="ellipsis"
                          name="ellipsis-h"
                          fill="currentColor"
                          height="16.5px"
                          width="16.5px"
                        />
                      </template>
                      <template #options>
                        <menu-item
                          v-if="file.metadata.type !== 'folder'"
                          @click="downloadFile(file)"
                        >
                          <unicon
                            width="16px"
                            height="16px"
                            name="file-download"
                            fill="var(--font-color-700)"
                          >
                          </unicon>
                          Descargar
                        </menu-item>
                        <menu-item
                          v-if="file.metadata.type === 'file'"
                          @click="openSendEmailModal([file.fileURL])"
                        >
                          <unicon width="16px" height="16px" name="envelope-upload"></unicon>
                          Email
                        </menu-item>
                        <menu-item
                          v-if="file.metadata.type === 'folder'"
                          @click="openEditFolderModal(file)"
                        >
                          <unicon width="16px" height="16px" name="pen"></unicon>
                          Editar
                        </menu-item>
                        <menu-item v-else @click="openEditFileModal(file)">
                          <unicon width="16px" height="16px" name="pen"></unicon>
                          Editar
                        </menu-item>
                        <span class="menu__divider"></span>
                        <menu-item :isDanger="true" @click="onDelete(file)">
                          <unicon
                            width="16px"
                            height="16px"
                            name="trash"
                            fill="var(--danger-color-400)"
                          ></unicon>
                          Eliminar
                        </menu-item>
                      </template>
                    </Menu>
                  </td>
                  <td>
                    <checkbox
                      :value="file.metadata.type === 'folder' ? file.id : file.fileURL"
                      v-model="selectedFiles"
                    />
                  </td>
                  <td class="number">
                    {{ index + 1 }}
                  </td>
                  <template>
                    <template v-for="data in metadata">
                      <td
                        :key="data.id"
                        @click="file.metadata.type === 'folder' ? changeFolder(file.id) : ''"
                      >
                        <div v-if="data.id === 'name'">
                          <figure
                            class="fileCard__img"
                          >
                            <img

                              :src="
                                require(`@/assets/fileTypes/${
                                  fileTypes.find(
                                    (fileType) =>
                                      fileType.Extension === `.${file.metadata.contentType}`,
                                  )
                                    ? fileTypes.find(
                                        (fileType) =>
                                          fileType.Extension === `.${file.metadata.contentType}`,
                                      ).svg
                                    : 'file.svg'
                                }`)
                              "
                              alt=""
                            />
                          </figure>
                          {{ file.metadata[data.id] || '---' }}
                        </div>
                        <div v-else-if="data.id === 'timeCreated'" class="log__date">
                          <span>
                            {{ dateToDMY(file.metadata[data.id]) }}
                          </span>
                          <span>
                            {{ file.metadata[data.id].toLocaleTimeString() }}
                          </span>
                        </div>
                        <div v-else-if="data.id === 'size' && file.metadata.type === 'folder'">
                          ---
                        </div>
                        <div v-else-if="data.id === 'size'">
                          {{ (file.metadata[data.id] / 1000 / 1000 || 0).toFixed(1) }} MB
                        </div>
                        <template v-else>
                          {{ file.metadata[data.id] || '---' }}
                        </template>
                      </td>
                    </template>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
          <empty-state
            v-if="!isLoading && !filteredFiles.length"
            label="archivos"
            @click="openNewFolderModal"
          />
          <div v-if="isLoading" class="loading"><loading-spinner /></div>
        </div>
      </div>
    </div>

    <folder-modal ref="modal__folder" :selectedFolderId="selectedFolderId" @update="filterFiles"/>
    <file-modal ref="modal__file" :selectedFolderId="selectedFolderId" @update="filterFiles"/>
    <send-email-modal ref="modal__sendEmail" />
    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Checkbox from '@/components/custom/Checkbox.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Button from '@/components/buttons/Button.vue';
import FileCard from '@/components/FileCard.vue';
import Filters from '@/components/filters/Filters.vue';
import SortItem from '@/components/SortItem.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import ButtonGroup from '@/components/buttons/ButtonGroup.vue';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import EmptyState from '@/components/EmptyState.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import { dateToDMY } from '@/dateFormats';
import ft from '@/fileTypes';
import FolderModal from './modals/FolderModal.vue';
import FileModal from './modals/FileModal.vue';
import SendEmailModal from './modals/SendEmailModal.vue';

export default {
  name: 'RoomFiles',
  components: {
    Breadcrumbs,
    Button,
    FileCard,
    Filters,
    SortItem,
    Menu,
    MenuItem,
    ButtonGroup,
    Checkbox,
    FolderModal,
    FileModal,
    LoadingSpinner,
    EmptyState,
    ConfirmDialogue,
    SendEmailModal,
  },

  data() {
    return {
      fileTypes: ft.fileTypes,
      dateToDMY,
      isLoading: true,
      displayType: 'table',
      searchField: '',
      isMenuOpen: false,
      showFilters: true,
      sortParam: { text: 'name', order: 'up' },
      filteredFiles: null,
      selectedFiles: [],
      selectedFolderId: '',
      selectAll: false,
      metadata: [
        {
          id: 'name',
          name: 'Nombre',
          type: 'text',
        },
        {
          id: 'timeCreated',
          name: 'Tiempo creado',
          type: 'text',
        },
        {
          id: 'size',
          name: 'Tamaño',
          type: 'text',
        },
        // {
        //   id: 'type',
        //   name: 'Tipo',
        //   type: 'text',
        // },
        {
          id: 'contentType',
          name: 'Formato',
          type: 'text',
        },
      ],
    };
  },

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('files', [
      'fetchFolders',
      'deleteFolder',
      'updateFolder',
      'addFolder',
      'uploadFile',
      'fetchFiles',
      'deleteFile',
    ]),

    isValidUrl(urlString) {
      const inputElement = document.createElement('input');
      inputElement.type = 'url';
      inputElement.value = urlString;

      return inputElement.checkValidity();
    },

    async downloadFile(file) {
      try {
        const url = await file.file.getDownloadURL();
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        const link = document.createElement('a');
        link.download = file.metadata.name;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        this.setAlert({
          state: 'error',
          message: 'No se ha podido descargar el archivo, intentelo de nuevo',
        });
      }
    },

    async onDelete(file) {
      if (file.metadata.type === 'folder') return;
      try {
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Eliminar archivo(s)',
          message:
            '¿Estas seguro que quieres eliminar uno o mas archivos? Eliminar un archivo lo removera permanentemente de tu lista, esta acción es irreversible.',
          isDestructive: true,
          okButtonText: 'Si, eliminar',
        });

        if (!ok) return;
        await this.deleteFile({ ...file, folderId: this.selectedFolderId });
        this.setAlert({
          state: 'success',
          message: 'Archivos(s) eliminado(s)',
        });
      } catch (err) {
        console.log(err);
        this.setAlert({
          state: 'error',
          message: 'No se ha podido eliminar el archivo, intentelo de nuevo',
        });
      }
    },

    openNewFolderModal() {
      this.$refs.modal__folder.open();
    },

    openEditFolderModal(folder) {
      this.$refs.modal__folder.selectedFolder = folder;
      this.$refs.modal__folder.open();
    },

    openEditFileModal(file) {
      this.$refs.modal__file.selectedFile = file;
      this.$refs.modal__file.open();
    },

    openSendEmailModal(files) {
      this.$refs.modal__sendEmail.open(
        files.map((url) => {
          const file = this.files.find(({ fileURL }) => fileURL === url);
          return {
            contentType: file.metadata.contentType,
            bucket: file.metadata.bucket,
            fullPath: file.metadata.fullPath,
            url: file.fileURL,
            name: file.metadata.name,
            refName: file.metadata.refName,
            id: file.metadata.customMetadata.id,
          };
        }),
      );
    },

    async changeFolder(folderId) {
      this.isLoading = true;
      this.filteredFiles = [];
      this.selectedFolderId = folderId;
      await this.fetchFiles(this.selectedFolderId);
      this.filterFiles();
      await new Promise((r) => setTimeout(r, 500));
      this.isLoading = false;
    },

    filterFiles(filters = []) {
      const activeFilters = [];
      filters.forEach((filter) => {
        const activeOptions = filter.options
          .filter((option) => option.active)
          .map((option) => option.id);

        if (activeOptions.length) {
          activeFilters.push({
            id: filter.id,
            type: filter.type,
            options: activeOptions,
          });
        }
      });
      if (activeFilters.length) {
        const files = this.files.filter(({ metadata }) => {
          for (let index = 0; index < activeFilters.length; index += 1) {
            const filter = activeFilters[index];
            const value = filter.options;
            const filterValue = metadata[filter.id].toString();
            if (!filterValue.includes(value)) return false;
          }
          return true;
        });
        this.filteredFiles = [...this.folders.sort(this.compare), ...files.sort(this.compare)];
      } else {
        this.filteredFiles = [...this.folders.sort(this.compare), ...this.files.sort(this.compare)];
      }
    },

    changeSortParam(text, direction) {
      this.sortParam.order = direction;
      this.sortParam.text = text;
      if (this.sortParam.text) {
        this.filteredFiles = [
          ...this.folders.sort(this.compare),
          ...this.filteredFiles
            .filter(({ metadata }) => metadata.type !== 'folder')
            .sort(this.compare),
        ];
      }
    },
    compare(a, b) {
      const isOrderUp = this.sortParam.order === 'up';
      const valueA = a.metadata[this.sortParam.text] || '';
      const valueB = b.metadata[this.sortParam.text] || '';
      if (valueA < valueB) {
        return isOrderUp ? -1 : 1;
      }
      if (valueA > valueB) {
        return isOrderUp ? 1 : -1;
      }
      return 0;
    },

    async handleFileChange(e) {
      const index = 0;
      const file = e.target.files[index];
      file.folderId = this.selectedFolderId;
      try {
        await this.uploadFile(file);
        this.setAlert({
          state: 'success',
          message: 'Ha subido un archivo',
        });
      } catch (err) {
        console.log(err);
        this.setAlert({
          state: 'error',
          message: 'No se ha podido subir el archivo, intentelo de nuevo',
        });
      }
    },

    getFamily(prevFolders, folder) {
      let family = [];
      if (!folder) return [...prevFolders];
      if (!folder.metadata.parentId) return [...prevFolders, folder];
      family = [
        ...this.getFamily(
          [...prevFolders, folder],
          this.allFolders.find(({ id }) => id === folder.metadata.parentId),
        ),
      ];
      return family;
    },
  },

  async created() {
    try {
      this.isLoading = true;
      await this.fetchFiles();
      if (!this.folders.length) await this.fetchFolders();
      await new Promise((r) => setTimeout(r, 500));
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.setAlert({
        state: 'error',
        message: 'Ocurrió un error al cargar los datos, por favor inténtelo nuevamente',
      });
      this.isLoading = false;
    }
  },

  computed: {
    ...mapState({
      company: (state) => state.company,
      allFolders: (state) => state.files.folders.map((folder) => ({
        ...folder,
        metadata: {
          ...folder.metadata,
          timeCreated: new Date(
            folder.metadata.timeCreated.seconds
              ? folder.metadata.timeCreated.seconds * 1000
              : folder.metadata.timeCreated,
          ),
        },
      })),
      files: (state) => state.files.files.map((file) => {
        const { name } = file.metadata;
        const lastIndex = name.lastIndexOf('.');
        return {
          ...file,
          metadata: {
            ...file.metadata,
            refName: name,
            name: file.metadata.customMetadata.name,
            timeCreated: new Date(file.metadata.timeCreated),
            contentType: name.slice(lastIndex + 1) || '',
          },
        };
      }),
    }),

    folders() {
      return this.allFolders.filter(({ metadata }) => metadata.parentId === this.selectedFolderId);
    },

    folderFamily() {
      const currFolder = this.allFolders.find(({ id }) => id === this.selectedFolderId);
      if (!currFolder) return [{ id: '', metadata: { name: 'Todos los archivos' } }];
      return [
        { id: '', metadata: { name: 'Todos los archivos' } },
        ...this.getFamily([], currFolder).reverse(),
      ];
    },
  },

  watch: {
    selectAll(selectAll) {
      if (selectAll) {
        this.selectedFiles = this.filteredFiles.map((file) => {
          const isFolder = file.metadata.type === 'folder';
          return isFolder ? file.id : file.fileURL;
        });
      } else this.selectedFiles = [];
    },
    files() {
      this.filterFiles();
    },
    folders() {
      this.filterFiles();
    },
  },
};
</script>

<style lang="scss" scoped>
.view__container {
  border-radius: 10px;
  background-color: white;

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}

.content {
  width: 100%;
  margin-top: 1rem;
  background-color: white;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;

  & > *:first-child {
    margin-bottom: 1rem;
  }
  & > div:nth-child(2) {
    overflow: auto;
    height: 100%;
  }
}

.files__container {
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 3rem / 4));
  gap: 1rem;
  overflow: auto;
  &-empty {
    display: flex;
    justify-content: center;
  }
}

.files__emptyState {
  height: 100%;
  display: flex;
  img {
    margin: auto;
    height: 15em;
    opacity: 0.7;
  }
}

.hidden {
  display: none;
}

.filter__container {
  display: flex;
  justify-content: space-between;
  & > div {
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.sort__divider {
  width: 1px;
  height: 30px;
  background-color: var(--gray-color-700);
  margin: 0 0.5rem;
}

table {
  width: 100%;
  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7),
  th:last-child {
    width: calc((100% - 7rem) / 4);
  }
  .category__label {
    display: flex;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 2px;
    &:hover {
      background-color: var(--gray-color-200);
    }
  }

  .selected {
    background-color: var(--gray-color-100);
  }

  .terminated {
    background-color: var(--danger-color-100);
  }

  tr:first-child {
    th {
      &.sortActive,
      &.sortActive * {
        color: var(--font-color-900);
      }
    }
  }

  th:not(:first-child):not(:nth-child(2)):not(:nth-child(3)) {
    padding: 0 1.5rem 0 0.3rem;
  }
  th:first-child,
  td:first-child {
    padding: 0.5rem;
    width: 2.03125rem;
    min-width: 2.03125rem;
    max-width: 2.03125rem;
  }
  th:nth-child(2),
  td:nth-child(2) {
    padding: 1rem;
    width: 3rem;
  }

  td:nth-child(4) {
    div {
      display: flex;
      gap: 1rem;
    }
  }
}
table .isFolder {
  cursor: pointer;
  &:hover {
    background-color: var(--gray-color-100);
  }
}
.file-input__label {
  cursor: pointer;
  display: flex;
  gap: 0.625rem;
  button {
    pointer-events: none;
  }
}
.log__date {
  display: flex;
  flex-flow: column;

  span:nth-child(2) {
    font-size: 0.7rem;
    color: var(--font-color-100);
  }
}
.loading {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.breadcrumb__folders {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  p {
    font-weight: var(--medium);
    color: var(--font-color-700);
  }
  .unicon {
    fill: var(--gray-color-500);
  }
  span p {
    cursor: pointer;
    color: var(--font-color-100);
    &:hover {
      color: var(--font-color-700);
    }
  }
}

.folders__buttons {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.fileCard__img {
  position: relative;
  width: 1rem;
  img {
    height: 1rem;
  }
}

tr:not(.isFolder) {
  td:nth-child(4) {
    height: fit-content;
    div {
      height: 19.2px;
      display: flex;
      align-items: center;
      gap: .8rem;
    }
  }
  .fileCard__img {
    width: 1.2rem;
    height: 19.2px;
    img {
      height: 100%;
      width: 1.2rem;
      position: absolute;
      left: -.15rem;
    }
  }
}
</style>
