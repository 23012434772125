<template>
  <div>
    <Modal size="slg" ref="modal__chart">
      <template #title> Email archivo </template>
      <template #content>
        <link rel="preload" :href="signatureURL" as="image" />
        <div class="steps__container">
          <div class="steps__header"><StepsHeader :steps="steps" :currentStep="currentStep" /></div>
          <div v-if="currentStep === 1" class="form__validation">
            <form>
              <div class="email__modal">
                <span class="email__content">
                  <div class="subject__input">
                    <p>Asunto:</p>
                    <input type="text" v-model="msg.subject" />
                  </div>
                  <div>
                    <tiptap-editor v-model="msg.html" @save="onSave" />
                  </div>
                </span>
              </div>
              <div class="modal__buttons">
                <Button
                  :disabled="isLoading"
                  type="button"
                  variant="text"
                  size="small"
                  @click="close"
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  :disabled="isLoading"
                  variant="primary"
                  size="small"
                  @click="currentStep += 1"
                >
                  Siguiente
                </Button>
              </div>
            </form>
          </div>
          <div v-if="currentStep === 2" class="form__validation">
            <div class="email__modal">
              <span class="email__content">
                <Filters
                  @filter="
                    (activeFilters) => {
                      filterEmployees(activeFilters);
                    }
                  "
                  :filters="[...defaultCategories, ...categories]"
                  :filtersOptions="[...defaultCategoriesOptions, ...options]"
                />
                <div class="employees__container">
                  <table ref="employeesTable">
                    <tbody>
                      <tr>
                        <th><checkbox v-model="selectAll" /></th>
                        <th>#</th>
                        <th>Estado</th>
                        <th>Número de documento</th>
                        <th>Nombre</th>
                        <th>Email</th>
                      </tr>
                      <template v-if="filteredEmployees.length && !isLoading">
                        <tr
                          v-for="(employee, index) in filteredEmployees.slice(
                            (currentPage - 1) * 100,
                            currentPage * 100,
                          )"
                          :key="employee.id"
                          :class="{
                            selected: selectedEmployees.includes(employee.id),
                          }"
                        >
                          <td>
                            <checkbox :value="employee.id" v-model="selectedEmployees" />
                          </td>
                          <td>{{ index + 1 + (currentPage - 1) * 100 }}</td>
                          <template
                            v-for="defaultCategory in defaultCategories.filter(({ id }) =>
                              ['state', 'documentNumber', 'name', 'email'].includes(id),
                            )"
                          >
                            <td v-if="defaultCategory.id === 'state'" :key="defaultCategory.id">
                              <span
                                class="state__cell"
                                :class="
                                  employee.state === 'Cesado'
                                    ? 'state__cell--terminated'
                                    : 'state__cell--active'
                                "
                                >{{ employee.state }}</span
                              >
                            </td>
                            <td v-else :key="defaultCategory.id">
                              {{ employee[defaultCategory.id] }}
                            </td>
                          </template>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </span>
            </div>
            <div class="modal__buttons">
              <Button
                :disabled="isLoading"
                type="button"
                variant="text"
                size="small"
                @click="close"
              >
                Cancelar
              </Button>
              <div>
                <Button
                  :disabled="isLoading"
                  type="button"
                  variant="secondary"
                  size="small"
                  @click="currentStep -= 1"
                >
                  Anterior
                </Button>
                <Button
                  @click="currentStep += 1"
                  :disabled="selectedEmployees.length === 0 || isLoading"
                  variant="primary"
                  size="small"
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </div>
          <div v-if="currentStep === 3" class="form__validation">
            <div class="email__modal">
              <span class="email__content">
                <div class="subject__input">
                  <p>Asunto:</p>
                  <input type="text" v-model="msg.subject" disabled />
                </div>
                <div>
                  <div class="html__content" v-html="msg.html" />
                </div>
                <div class="email__signature">
                  <img :src="signatureURL" alt="" />
                </div>
                <div class="email__attachments">
                  <div v-for="file in files" :key="file.id" class="email__attachment">
                    <figure class="fileCard__img">
                      <img
                        :src="
                          require(`@/assets/fileTypes/${
                            fileTypes.find(
                              (fileType) => fileType.Extension === `.${file.contentType}`,
                            )
                              ? fileTypes.find(
                                  (fileType) => fileType.Extension === `.${file.contentType}`,
                                ).svg
                              : 'file.svg'
                          }`)
                        "
                        alt=""
                      />
                    </figure>
                    <p>{{ file.name || '---' }}.{{ file.contentType }}</p>
                  </div>
                </div>
              </span>
            </div>
            <div class="modal__buttons">
              <Button
                :disabled="isLoading"
                type="button"
                variant="text"
                size="small"
                @click="close"
              >
                Cancelar
              </Button>
              <div>
                <Button
                  :disabled="isLoading"
                  type="button"
                  variant="secondary"
                  size="small"
                  @click="currentStep -= 1"
                >
                  Anterior
                </Button>
                <Button
                  :disabled="isLoading"
                  variant="primary"
                  size="small"
                  @click="currentStep += 1"
                >
                  Siguiente
                </Button>
              </div>
            </div>
          </div>
          <div v-if="currentStep === 4" class="form__validation">
            <div class="email__modal">
              <span class="email__content">
                <div class="employees__container">
                  <div class="confirmation__message">
                    <p>Los siguientes empleados no tienen un email y no seran procesados</p>
                  </div>
                  <table ref="employeesTable">
                    <tbody>
                      <tr>
                        <th></th>
                        <th>#</th>
                        <th>Número de documento</th>
                        <th>Nombre</th>
                        <th>Email</th>
                      </tr>
                      <template v-if="selectedEmployeesEmails.filter(({ email }) => !email)">
                        <tr
                          v-for="(employee, index) in selectedEmployeesEmails.filter(
                            ({ email }) => !email,
                          )"
                          :key="employee.id"
                        >
                          <td>
                            <div class="x">
                              <unicon name="exclamation-triangle" height="16" width="16"></unicon>
                            </div>
                          </td>
                          <td>{{ index + 1 + (currentPage - 1) * 100 }}</td>
                          <template
                            v-for="defaultCategory in defaultCategories.filter(({ id }) =>
                              ['documentNumber', 'name', 'email'].includes(id),
                            )"
                          >
                            <td :key="defaultCategory.id">
                              {{ employee[defaultCategory.id] || '-' }}
                            </td>
                          </template>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </span>
            </div>
            <div class="modal__buttons">
              <Button
                :disabled="isLoading"
                type="button"
                variant="text"
                size="small"
                @click="close"
              >
                Cancelar
              </Button>
              <div>
                <Button
                  :disabled="isLoading"
                  type="button"
                  variant="secondary"
                  size="small"
                  @click="currentStep -= 1"
                >
                  Anterior
                </Button>
                <Button @click="onAction" :disabled="isLoading" variant="primary" size="small">
                  Enviar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import Checkbox from '@/components/custom/Checkbox.vue';
import Button from '@/components/buttons/Button.vue';
import Modal from '@/components/Modal.vue';
import TiptapEditor from '@/components/TiptapEditor.vue';
import Filters from '@/components/filters/Filters.vue';
import StepsHeader from '@/components/StepsHeader.vue';
import ft from '@/fileTypes';
import DEFAULT_CATEGORIES from '@/defaultCategories';
import { timeStampToAge, timeStampToDate } from '@/dateFormats';

export default {
  name: 'SendEmailModal',
  components: {
    StepsHeader,
    Button,
    Modal,
    TiptapEditor,
    Checkbox,
    Filters,
  },
  data() {
    return {
      isLoading: false,
      htmlTemplate: '',
      msg: { subject: '', html: '' },
      files: [],
      steps: [
        { name: 'Crear correo', icon: 'envelope-edit' },
        { name: 'Seleccionar destinatarios', icon: 'users-alt' },
        { name: 'Preview', icon: 'eye' },
        { name: 'Confirmación', icon: 'thumbs-up' },
      ],
      currentStep: 1,
      currentPage: 1,
      defaultCategories: DEFAULT_CATEGORIES.CATEGORIES,
      defaultCategoriesOptions: DEFAULT_CATEGORIES.OPTIONS,
      sortParam: { text: 'name', order: 'up' },
      filteredEmployees: [],
      selectedEmployees: [],
      timeStampToAge,
      timeStampToDate,
      selectAll: false,
      signatureURL: '',
      fileTypes: ft.fileTypes,
    };
  },

  methods: {
    ...mapActions('employees', ['fetchEmployees']),
    ...mapActions('categories', ['fetchCategories']),
    ...mapActions('options', ['fetchOptions']),
    ...mapMutations(['setAlert']),
    ...mapActions('files', ['updateFile']),
    ...mapActions(['getSignature']),
    ...mapActions('emails', ['sendEmail']),

    async setSignatureURL() {
      try {
        const file = await this.getSignature();
        const url = await file.getDownloadURL();
        this.signatureURL = url;
      } catch (e) {
        console.log(e);
      }
    },

    onSave() {
      this.htmlTemplate = this.msg.html;
    },

    async onAction() {
      this.isLoading = true;
      try {
        const emailList = [];
        this.selectedEmployeesEmails.forEach(({ email }) => {
          if (email) emailList.push(email);
        });
        console.log(
          await this.sendEmail({
            signatureURL: this.signatureURL,
            files: this.files,
            ...this.msg,
            subject: this.msg.subject || 'None',
            emails: emailList,
          }),
        );
        this.setAlert({
          state: 'success',
          message: 'Archivo enviado',
        });
      } catch (error) {
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
        console.log(error);
      }
      this.isLoading = false;
      this.close();
    },

    async open(files) {
      this.files = files;
      this.$refs.modal__chart.open();
    },

    async close() {
      this.$refs.modal__chart.close();

      this.currentStep = 1;
      this.selectedEmployees = [];
      this.htmlTemplate = this.company.emailTemplate;
      this.msg = { subject: '', html: this.htmlTemplate };
      this.filterEmployees();
      this.filteredEmployees = this.filteredEmployees.sort(this.compare);
    },

    getOptionName(id) {
      const option = this.options.find((opt) => opt.id === id);
      return option ? option.name : '';
    },

    compare(a, b) {
      const isOrderUp = this.sortParam.order === 'up';
      let valueA = this.getOptionName(a[this.sortParam.text]) || a[this.sortParam.text] || '';
      let valueB = this.getOptionName(b[this.sortParam.text]) || b[this.sortParam.text] || '';
      if (valueA.seconds) valueA = valueA.seconds;
      if (valueB.seconds) valueB = valueB.seconds;
      if (valueA < valueB) {
        return isOrderUp ? -1 : 1;
      }
      if (valueA > valueB) {
        return isOrderUp ? 1 : -1;
      }
      return 0;
    },

    getState(timeStamp) {
      if (!timeStamp || !timeStamp.seconds) return 'Activo';
      return new Date() > this.timeStampToDate(timeStamp) ? 'Cesado' : 'Activo';
    },

    filterEmployees(filters = []) {
      this.isLoading = true;
      const activeFilters = [];
      let employees = JSON.parse(JSON.stringify(this.employees));
      filters.forEach((filter) => {
        const activeOptions = filter.options
          .filter((option) => option.active)
          .map((option) => option.id);

        if (activeOptions.length) {
          activeFilters.push({
            id: filter.id,
            type: filter.type,
            options: activeOptions,
          });
        }
      });
      if (activeFilters.length) {
        employees = employees.filter((employee) => {
          for (let index = 0; index < activeFilters.length; index += 1) {
            const filter = activeFilters[index];
            let value = '';
            let filterValue = null;
            if (['date', 'month'].includes(filter.type)) {
              filterValue = this.YMDToTimeStamp(filter.options[0]).seconds.toString();
              value = employee[filter.id].seconds.toString();
              console.log(filterValue === value);
              if (filterValue !== value) return false;
            }
            if (filter.type === 'text') {
              filterValue = employee[filter.id].toString();
              value = filter.options;
            } else {
              filterValue = filter.options;
              value = employee[filter.id];
            }
            if (!filterValue.includes(value)) return false;
          }
          return true;
        });
      }
      if (this.sortParam.text) {
        this.filteredEmployees = employees.sort(this.compare);
      } else this.filteredEmployees = employees;
      this.isLoading = false;
    },
  },

  computed: {
    ...mapState({
      company: (state) => state.company,
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
      employees(state) {
        return state.employees.employees.sort(this.compare).map((employee) => ({
          ...employee,
          age: this.timeStampToAge(employee.birthDate).toString(),
          state: this.getState(employee.terminationDate),
          otherIncomesArray: employee.otherIncomes,
          otherIncomes: employee.otherIncomes.reduce((acc, val) => acc + val, 0),
        }));
      },
    }),

    selectedEmployeesEmails() {
      return this.employees
        .filter((employee) => this.selectedEmployees.includes(employee.id))
        .map((employee) => ({
          email: employee.email || '',
          id: employee.id,
          name: employee.name,
          documentNumber: employee.documentNumber,
        }));
    },
  },

  watch: {
    selectAll() {
      if (this.selectAll) {
        this.selectedEmployees = this.filteredEmployees.map((employee) => employee.id);
      } else this.selectedEmployees = [];
    },
  },

  async mounted() {
    this.htmlTemplate = this.company.emailTemplate;
    this.msg.html = this.htmlTemplate;
    try {
      await this.setSignatureURL();
      await this.fetchEmployees();
      await this.fetchCategories();
      await this.fetchOptions();
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.setAlert({
        state: 'error',
        message: 'Ocurrió un error al cargar los datos, por favor inténtelo nuevamente',
      });
    }
    this.filterEmployees();
    this.filteredEmployees = this.filteredEmployees.sort(this.compare);
  },
};
</script>

<style lang="scss" scoped>
.steps__container {
  height: 100%;
  display: flex;
  flex-flow: column;
}
.form__validation {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  form {
    height: 100%;
    display: flex;
    flex-flow: column;
  }
}

.email__modal {
  padding: 1.5rem 2rem;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-flow: column;
}

.email__content {
  overflow: hidden;
  height: 100%;
  .subject__input {
    border-bottom: solid 2px var(--gray-color-200);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
    p {
      font-weight: var(--medium);
      color: var(--font-color-300);
    }
    input {
      font-weight: var(--medium);
      flex-grow: 1;
      color: var(--font-color-700);
    }
  }
  .html__content {
    border-radius: 0.375rem;
  }
  & > div:nth-child(2) {
    overflow: auto;
  }
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  .container {
    margin-left: 0.3rem;
  }
}

.employees__container {
  overflow: auto;
  margin-top: 0.5rem;

  table {
    width: 100%;
    th:first-child,
    td:first-child,
    th:nth-child(2),
    td:nth-child(2) {
      padding: 1rem;
      width: 3rem;
      min-width: 3rem;
      max-width: 3rem;
    }
  }
}

.modal__buttons {
  justify-content: space-between;
  div {
    display: flex;
    gap: 1rem;
  }
}

.email__signature {
  padding: 0.7rem 0rem;
  img {
    height: 30px;
  }
}

.selected {
  background-color: var(--gray-color-100);
}

.x {
  fill: var(--danger-color-400);
  display: grid;
  place-items: center;
}

.confirmation__message {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  padding-bottom: .5rem;
}

.steps__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  padding-bottom: 0;
}

.email__attachments {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.email__attachment {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: var(--gray-color-100);
  padding: 0.7rem;
  border-radius: 5px;
  width: 15rem;
  img {
    height: 1rem;
  }
  p {
    color: var(--font-color-700);
    font-weight: var(--medium);
  }
}
</style>
