<template>
  <div class="fileCard__container" :class="{ isFolder: file.metadata.type === 'folder' }">
    <div class="fileCard__header">
      <Menu direction="below">
        <template #label>
          <unicon
            class="ellipsis"
            name="ellipsis-v"
            fill="currentColor"
            height="16.5px"
            width="16.5px"
          />
        </template>
        <template #options>
          <menu-item v-if="file.metadata.type !== 'folder'" @click="$emit('download', file)">
            <unicon
              width="16px"
              height="16px"
              name="file-download"
              fill="var(--font-color-700)"
            ></unicon>
            Descargar
          </menu-item>
          <menu-item v-if="file.metadata.type === 'file'" @click="$emit('email', file)">
            <unicon width="16px" height="16px" name="envelope-upload"></unicon>
            Email
          </menu-item>
          <menu-item @click="$emit('edit', file)">
            <unicon width="16px" height="16px" name="pen" fill="var(--font-color-700)"></unicon>
            Editar
          </menu-item>
          <span class="menu__divider"></span>
          <menu-item @click="$emit('delete', file)" :isDanger="true">
            <unicon width="16px" height="16px" name="trash" fill="var(--danger-color-400)"></unicon>
            Eliminar
          </menu-item>
        </template>
      </Menu>
    </div>
    <div class="fileCard__body" @click="$emit('click')">
      <img
        class="fileCard__img"
        :src="
          require(`@/assets/fileTypes/${
            fileTypes.find((fileType) => fileType.Extension === `.${file.metadata.contentType}`)
              ? fileTypes.find((fileType) => fileType.Extension === `.${file.metadata.contentType}`)
                  .svg
              : 'file.svg'
          }`)
        "
        alt=""
      />
      <p>{{ file.metadata.name }}</p>
    </div>
    <div class="fileCard__footer">
      <div>
        <p>Size:</p>
        <p v-if="file.metadata.type !== 'folder'">
          {{ (file.metadata.size / 1000 / 1000 || 0).toFixed(1) }} MB
        </p>
        <p v-else>---</p>
      </div>
      <div class="fileCard__pfp">
        <img :src="''" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import ft from '../fileTypes';

export default {
  name: 'FileCard',

  components: {
    Menu,
    MenuItem,
  },

  data() {
    return {
      fileTypes: ft.fileTypes,
    };
  },

  props: {
    file: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapMutations(['setAlert']),
  },

  computed: {
    ...mapState(['userProfile', 'rooms']),
    ...mapGetters(['getUserById']),
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 0.7rem;
  line-height: 1rem;
}
.fileCard__container {
  height: 12rem;
  width: 100%;
  background-color: var(--gray-color-100);
  border-radius: 4px;
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.fileCard__img {
  height: 3.2rem;
}
.fileCard__header {
  height: 15%;
  display: flex;
  align-items: center;
}
.fileCard__body {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  height: 60%;
  margin: auto;
  width: 85%;
  p {
    text-align: center;
  }
}
.fileCard__footer {
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div p:first-child {
  }
  & > div p:nth-child(2) {
    color: var(--font-color-700);
  }
  .fileCard__pfp img {
    height: 1.6rem;
    border-radius: 8px;
    filter: grayscale(100%) brightness(0.6);
  }
}

.isFolder {
  .fileCard__body {
    cursor: pointer;
  }

  &:hover {
    background-color: var(--gray-color-200);
    h2 {
      color: white;
    }
  }
}
</style>
