<template>
  <div>
    <Modal size="sm" ref="modal__chart">
      <template #title>
        Editar archivo
      </template>
      <template #content>
        <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
          <form @submit.prevent="handleSubmit(onAction)">
            <div class="chart__modal">
              <span>
                <custom-input v-model="file.name" label="Nombre" rules="required" />
              </span>
            </div>
            <div class="modal__buttons">
              <Button
                :disabled="isLoading"
                type="button"
                variant="text"
                size="small"
                @click="close"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                :disabled="invalid || isLoading"
                variant="primary"
                size="small"
              >
                {{ !isLoading ? 'Editar archivo' : 'Editando...' }}
              </Button>
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Button from '@/components/buttons/Button.vue';
import CustomInput from '@/components/custom/CustomInput.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'FileModal',
  components: {
    Button,
    Modal,
    CustomInput,
    ValidationObserver,
  },
  data() {
    return {
      file: {},
      isLoading: false,
      selectedFile: null,
    };
  },

  props: {
    selectedFolderId: {
      type: String,
      default: '',
    },
  },

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('files', ['updateFile']),

    async onAction() {
      this.isLoading = true;
      try {
        await this.updateFile({
          metadata: this.file,
          folderId: this.selectedFolderId,
        });
        this.setAlert({
          state: 'success',
          message: 'Archivo editado',
        });
      } catch (error) {
        console.log(error);
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      }
      this.isLoading = false;
      this.close();
      this.$emit('update');
    },

    async open() {
      if (this.selectedFile) {
        this.file = JSON.parse(JSON.stringify(this.selectedFile.metadata));
        this.file.timeCreated = this.selectedFile.metadata.timeCreated;
      }
      this.$refs.modal__chart.open();
    },

    close() {
      this.file = {};
      this.selectedFile = null;
      this.$refs.modal__chart.close();
    },
  },

  computed: {
    ...mapState({}),
  },
};
</script>

<style lang="scss" scoped>
.form__validation {
  height: 100%;

  form {
    height: 100%;
  }
}

.chart__modal {
  padding: 1.8rem 1.8rem;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;
}
</style>
